//Tipos de salidas 
module.exports = {

    cutOutCode: {
        //Recortes de jamón
        1:31,//'AREPERO',
        2:31,//'ESPALDA',
        3:31,//'PIERNA',
        4:31,//'FIAMBRE',
        5:31,//'PASTEL CUADRADO',
        6:31,//'PASTEL DE CARNE REDONDO',
        63:31,//'PASTEL DE CARNE REDONDO',
        55:31,//mozzarella',
        56:31,//'JAMON AHUMADO'
        80:31,//'BOLOGNA'
        74:31,//'AREPERO MOHAN',
        89:31,//'FIAMBRE MOHAN',
        90:31,//'Mozzarella disroca'
        20:31,//'SALCHICHA'
        36:31,//'QUESO DURO'
        84:31,//'mortadela'
        69:31,//'salchicha de pollo'
        78:31,//'planchado'
        40:31,//'mortadela con pistacho'
        67:31,//'bologna de cerdo'
        41:31,//'chorizada'
        

        // RECORTES DE AHUMADOS 


        10:32,//'TOCINETA AHUMADA',
        13:32,//'CHULETA AHUMADA',
        18:32,//'LOMO CANADIENSE',
        19:32,//'LOMO TIPO YORK',
        46:32,//'PERNIL AHUMADO'
        48:32,//'LOMITO AHUMADO'
        15:32,//'QUESO AMARILLO CAMPORICO'
        70:32,//'QUESO AMARILLO LA ABUELA'
        83:32,//'QUESO AMARILLO TREBOL'
        96:32,//'QUESO AMARILLO DEL VALLE'
        14:32,//'CHORIZO CERVECERO'

    },

}
